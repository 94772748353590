import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import Testimonials from '@solid-ui-blocks/Testimonials/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'

const PrivacyPolicy = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='About Us' />
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <p className='text-center text-xl font-bold'>
        Privacy Policy of Budding Superstars
      </p>
      <br/>
      <p style={styles.policyHeader}>
        About Us
      </p>
      <p style={styles.policyParagraph}>
        Budding Superstars Education Practice is a national brand delivering bespoke educational tutoring programmes to individuals (pupils) through a franchise model in Scotland.  The Franchisor is Budding Superstars, a Scottish Company.  This website <a href="https://www.buddingsuperstars.com"><u>www.buddingsuperstars.com</u></a> (“this site“) is operated by Budding Superstars.
      </p>
      <p style={styles.policyParagraph}>
        The purpose of this site is to provide information about our centres, franchise opportunities and the services we offer. This Privacy Policy is designed to give you, as a user of this site, information about how we obtain and process your personal data in connection with your use of this site.       
      </p>
      <p style={styles.policyParagraph}>
        References in this Privacy Policy to the “we”, “us” and “our” are to Budding Superstars.      </p>
      <p style={styles.policyParagraph}>
        We are responsible for deciding how we hold and use personal information about you. We are committed to protecting and respecting your privacy and ensuring that personal and sensitive information is gathered in compliance with this Privacy Policy.      </p>
      <p style={styles.policyParagraph}>
        By using this site, we may collect, use, store and transfer different kinds of personal data about you. In respect of all users of this site, the personal data we may collect includes information you send to us when making an enquiry, booking a free assessment and reserving a place at one of our franchise information sessions. We may also collect electronic data which is processed when accessing or browsing this site. Similarly, we may also collect personal information from you, such as your name, age, date of birth and contact details.      
      </p>
      <p style={styles.policyParagraph}>
        Please read this Privacy Policy carefully (together with our <a href="http://buddingsuperstars.com/cookie-policy/"><u>Cookies Policy</u></a>)       </p>
      <p style={styles.policyParagraph}>
        From time to time we may update this Privacy Policy and issue other privacy or fair processing notices to you relating to the way in which we collect personal data about you which we will bring to your attention.       
      </p>
      
      <p style={styles.policyHeader}>
        How to Contact Us
      </p>
      <p style={styles.policyParagraph}>
        This Privacy Policy was drafted with brevity and clarity in mind. It does not provide exhaustive detail of all aspects of our collection and use of personal information. However, we are happy to provide any additional information or explanation needed. For website users based in the UK, the controller of your personal data will usually be Budding Superstars. If you have any requests or concerns about any information contained within our Privacy Policy, please email us at info@buddingsuperstars.com or write to:      
      </p>
      <p style={styles.policyParagraph}>
        Budding Superstars<br/>
        One Tech Hub <br/>
        Schoolhill<br/>
        AB10 1JQ<br/>
      </p>
      <p style={styles.policyParagraph}>
        You can also telephone us on 0795 396 142.
      </p>

      <br/>
      <p className='text-center text-xl font-bold'>
        Our privacy promise 
      </p>
      <p style={styles.policyParagraph}>
        We promise:
        <p className='ml-10'>
          <li>To keep your data safe and private. </li>
          <li>Not to sell your data. </li>
        </p>
      </p>

      <p style={styles.policyHeader}>
        The data we collect about you 
      </p>
      <p style={styles.policyParagraph}>
        Personal data, or personal information, means any information relating to an identifiable individual. It does not include any data or information which relates to a person that cannot be identified or where the person’s identity has been removed (i.e. anonymous data). It also does not include information relating solely to a business or other organisation, rather than to a person. 
      </p>
      <p style={styles.policyParagraph}>
        By using this site, we may collect, use, store and transfer different kinds of personal data about you, which we have grouped together as follows:
      </p>

      <table className='w-2/3 text-justify mx-auto align-top'>
        <tr>
          <th>Type of Personal Data</th>
          <th>Description</th>
        </tr>
        <tr className='align-top'>
          <th className=''>Identity Data</th>
          <td>Data which identifies you (including name, username, title, school year, date of birth and gender)</td>
        </tr>
        <br/>
        <tr className='align-top'>
          <th>Contact Data</th>
          <td>Contact details (including postal address, telephone number and email address)</td>
        </tr>
        <br/>
        <tr className='align-top'>
          <th className='text-left'>Marketing and Communications Data </th>
          <td>Data which we capture when you sign up to newsletters, including your communications preferences </td>
        </tr>
        <br/>
        <tr className='align-top'>
          <th>Usage Data</th>
          <td>Information about how you use this site and our services, including how you navigate this site and if you encounter any problems </td>
        </tr>
        <br/>
        <tr className='align-top'>
          <th>Educational Data </th>
          <td>Information about you or your child’s education history and academic cycle that you submit on this site when you book a free assessment or which you may provide to us when you make an enquiry</td>
        </tr>
        <br/>
        <tr className='align-top'>
          <th>Social Media Data</th>
          <td>When you connect with us or like or follow our social media accounts we may have access to your personal data through the social media platform, including your social media handle, photograph, date of birth, location, occupation, interests and other information and content you make available via your social media accounts </td>
        </tr>
        <br/>
        <tr className='align-top'>
          <th>Technical Data</th>
          <td>Electronic information which is automatically logged/stored by processing equipment, including internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this site.</td>
        </tr>
      </table>
      
      <br/>
      <p style={styles.policyParagraph}>
        We may also collect, use and share <b>aggregated</b> data such as statistical or demographic data for any purpose. Aggregated data may be derived from your personal data but is not considered personal data in law as this data does not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific feature of this site. However, if we combine or connect aggregated data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this Privacy Policy.       
      </p>
      <p style={styles.policyParagraph}>
        We do not collect <b>special categories of personal data</b> about you through your use of this site (this includes details about your race or ethnicity, religious or philosophical beliefs, political opinions, information about your health and genetic and biometric data). Nor do we collect any information about criminal convictions and offences. However, if we enter into an agreement with you (as a student (or guardian of a student) tutor, or franchisee), we may collect this type of information. We will direct you to our applicable privacy policy covering the processing of such data at the appropriate time.  Please contact us if you require further information in the meantime.       
      </p>

      <br/>
      <p className='text-center text-xl font-bold'>
        How we collect personal data 
      </p>
      <br/>
      <p style={styles.policyHeader}>
        Direct interactions
      </p>
      <p style={styles.policyParagraph}>
        The majority of the personal data we hold about you is collected when you correspond or interact with us directly, complete online application forms, (via social media, by post, phone, email, telephone or otherwise).       
      </p>
      <p style={styles.policyParagraph}>
        This includes personal data you provide when you:
        <p className='ml-10'>
          <li>make an enquiry; </li>
          <li>subscribe to receiving marketing communications; </li>
          <li>reserve a place at one of our franchise information sessions;</li>
          <li>complete a survey;</li>
          <li>connect with us, follow us or ‘like’ us on social media; </li>
        </p>
      </p>
      <p style={styles.policyParagraph}>
        The categories of personal data we collect in this way include Identity, Contact, Profile, Social Media, Usage, Educational and Marketing and Communications Data.     
      </p>

      <p style={styles.policyHeader}>
        Automated technologies or interactions
      </p>
      <p style={styles.policyParagraph}>
        When you interact with this site, our systems will automatically collect Technical Data and Usage Data about your equipment, browsing actions and patterns. We collect this personal data by using cookies server logs and other similar technologies. We may also receive Technical Data about you if you visit other websites employing our cookies. We do this to find out things such as the number of visitors to the various parts of this site.  Please see our Cookies Policy at <a href="https://www.buddingsuperstars.com/cookie-policy">www.buddingsuperstars.com/cookie-policy</a>
      </p>

      <p style={styles.policyHeader}>
        Third parties 
      </p>
      <p style={styles.policyParagraph}>
      We may also receive personal data about you from various third parties as set out below:
        <p className='ml-10'>
          <li>Social Media Data from the operators of social media platforms, including Facebook, Instagram, LinkedIn, Twitter and Google+ who are based inside and outside of the EU. </li>
          <li>Technical Data from the following parties: </li>
          •	Analytics providers, such as Google Analytics and Facebook pixel tag <br/>
          •	Advertising networks<br/>
          •	Search information providers <br/>
        </p>
      </p>
      
      <br/>
      <p className='text-center text-xl font-bold'>
        How and why we use personal data
      </p>
      <br/>
      <p style={styles.policyParagraph}>
      We will only collect and process your personal data where we have a legal basis to do so. This legal basis will vary depending on the manner and purpose for which we are collecting your personal information. The circumstances in which we may use your personal data are as follows:
        <p className='ml-10'>
          <li>Where it is necessary for the <b>performance of a contract</b> to which you are a party or to take steps at your request before entering into such a contract; </li>
          <li>Where it is necessary to <b>comply with a legal or regulatory obligation</b> that we are subject to; </li>
          <li>Where it is necessary for our <b>legitimate interests</b> (or those of a third party) and your interests and fundamental rights do not override those interests; or  </li>
          <li>Where we have your <b>consent</b> to do so, subject to your right to withdraw consent (further details provided in the section headed “Your rights” below).</li>
        </p>
      </p>
      <p style={styles.policyParagraph}>
        We have set out in the table below a description of all the ways we plan to use your personal data, and which of the above legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.      
      </p>
      <p style={styles.policyParagraph}>
        Note that we may process your personal data for more than one lawful ground depending on the specific purpose for which we are using your data. Please contact us if you require further detail about the specific legal ground we are relying on to process your personal data where more than one ground has been set out in the table below.
      </p>

      <br/>
      <table className='w-3/4 text-left mx-auto align-top'>
        <tr className='align-top'>
          <th><p className='ml-0'>What we use your personal information for</p></th>
          <th><p className='ml-3'>Type of data</p></th>
          <th><p className='ml-3'>Lawful basis for processing</p></th>
          <th><p className='ml-3'>Basis of legitimate interest (where applicable)</p></th>
        </tr>
        <tr className='align-top'>
          <td>To manage our relationship with you, this will include notifying you about changes to our terms or Privacy Policy.</td>
          <td>
            <p className='ml-7'>
              <li>Identity</li>
              <li>Contact</li>
              <li>Profile</li>
              <li>Social Media</li>
              <li>Marketing and Communications</li>
            </p>
          </td>
          <td>
            <p className='ml-7'>
              <li>Necessary to comply with a legal obligation</li>
              <li>Necessary for our legitimate interests</li>
            </p>
          </td>
          <td>
            <p className='ml-2'>
              To conduct our business and to keep our records updated
            </p>
          </td>
        </tr>
        <br/>

        <tr className='align-top'>
          <td>To administer and protect our business and this site, including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data</td>
          <td>
            <p className='ml-7'>
              <li>Identity</li>
              <li>Contact</li>
              <li>Usage</li>
              <li>Technical</li>
            </p>
          </td>
          <td>
            <p className='ml-7'>
              <li>Necessary to comply with a legal obligation</li>
              <li>Necessary for our legitimate interests</li>
            </p>
          </td>
          <td>
            <p className='ml-2'>
              For running of our business, provision of administration and IT services, network security and to prevent fraud            
            </p>
          </td>
        </tr>
        <br/>

        <tr className='align-top'>
          <td>To set up, manage access to, and maintain the security of, your online account for the Student Portal</td>
          <td>
            <p className='ml-7'>
              <li>Identity</li>
              <li>Contact</li>
              <li>Profile</li>
              <li>Usage</li>
              <li>Technical</li>
            </p>
          </td>
          <td>
            <p className='ml-7'>
              <li>Consent – given at the time of registering an online account for the Student Portal</li>
              <li>Necessary for our legitimate interests</li>
              <li>Necessary for the performance of a contract with you</li>
            </p>
          </td>
          <td>
            <p className='ml-2'>
              To conduct our business – to be able to perform our contracts with students and conduct our business             
            </p>
          </td>
        </tr>
        <br/>

        <tr className='align-top'>
          <td>To deliver relevant website content and advertisements to you based upon your preferences.</td>
          <td>
            <p className='ml-7'>
              <li>Identity</li>
              <li>Contact</li>
              <li>Profile</li>
              <li>Usage</li>
              <li>Marketing and Communications</li>
            </p>
          </td>
          <td>
            <p className='ml-7'>
              <li>Necessary for our legitimate interests</li>
            </p>
          </td>
          <td>
            <p className='ml-2'>
              To develop our services and grow our business             
            </p>
          </td>
        </tr>
        <br/>

        <tr className='align-top'>
          <td>To use data analytics to improve this site and our services, marketing, customer relationships and experiences</td>
          <td>
            <p className='ml-7'>
              <li>Identity</li>
              <li>Contact</li>
              <li>Technical</li>
              <li>Usage</li>
            </p>
          </td>
          <td>
            <p className='ml-7'>
              <li>Necessary for our legitimate interests</li>
            </p>
          </td>
          <td>
            <p className='ml-2'>
              To define our customer base, to keep this site updated and relevant, to develop our business and to inform our marketing strategy         
            </p>
          </td>
        </tr>
        <br/>

        <tr className='align-top'>
          <td>To deal with and respond to queries submitted to us via this site, social media accounts, by post, email or by telephone</td>
          <td>
            <p className='ml-7'>
              <li>Identity</li>
              <li>Contact</li>
              <li>Social Media</li>
              <li>Profile</li>
              <li>Marketing and Communications</li>
            </p>
          </td>
          <td>
            <p className='ml-7'>
              <li>Consent – given at the time of contact</li>
              <li>Necessary for our legitimate interests</li>
            </p>
          </td>
          <td>
            <p className='ml-2'>
              To conduct our business and improve our services and keep our records up-to-date         
            </p>
          </td>
        </tr>
      </table>

      <br/>
      <p style={styles.policyHeader}>
        Marketing 
      </p>
      <p style={styles.policyParagraph}>
        <b>Marketing communications from us: </b>We may send you marketing communications if you:
        <p className='ml-10'>
          <li>are a student or a student’s parent/guardian and you have not opted out of receiving marketing communications from us; </li>
          <li>have booked a free assessment and you have consented to receive marketing communications from us; or</li>
          <li>have otherwise consented to receive marketing communications from us. </li>
        </p>
      </p>
      <p style={styles.policyParagraph}>
        <b>Third party marketing: </b> We will only share your personal data with another company for marketing purposes if you have expressly consented to us doing so.
      </p>
      <p style={styles.policyParagraph}>
        <b>Opting out: </b>You can ask us and our franchisees to stop sending you marketing communications at any time, by:
        <p className='ml-10'>
          <li>clicking the unsubscribe link in the footer of any marketing email from us;</li>
          <li>contact us on <a href="mailto:info@buddingsuperstars.com"><u>info@buddingsuperstars.com</u></a> </li>
        </p>
      </p>
      
      <p style={styles.policyHeader}>
        Change of purpose
      </p>
      <p style={styles.policyParagraph}>
        We will only use your personal data for the purposes for which we originally collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If we wish to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.  
      </p>
      <p style={styles.policyParagraph}>
        We may process your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law. 
      </p>

      <p style={styles.policyHeader}>
        If you fail to provide personal data
      </p>
      <p style={styles.policyParagraph}>
        Where we need to collect personal data from you in order to comply with our legal obligations or to perform a contract we have with you and you fail to provide that data when requested, we may not be able to perform the relevant contract (for example, to provide you with services). In this case, we may have to cancel the relevant contract.  
      </p>

      <p style={styles.policyHeader}>
        Cookies
      </p>
      <p style={styles.policyParagraph}>
        We use Google Analytics to help analyse use of our Website. This analytical tool uses “Cookies” which are small text files placed on your computer to collect standard internet log information and visitor behaviour information.  You can find out more about the way cookies work and Google Analytics on <a href="https://www.cookiecentral.com"><u>www.cookiecentral.com</u></a> and or <a href="https://www.allaboutcookies.org"><u>www.allaboutcookies.org</u></a> and <a href="https://www.google.com/analytics"><u>www.google.com/analytics</u></a>.    
      </p>
      <p style={styles.policyParagraph}>
        This site uses cookies for a number of purposes, for instance to enable us to identify which pages are being used, analyse data about web page traffic, build a demographic profile and improve this site in order to tailor it to customer needs. Overall, cookies help us to provide you with a better site, by enabling us to monitor which pages you find useful and which you do not.  
      </p>
      <p style={styles.policyParagraph}>
        You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies (and the above websites tell you how to do this). If you disable or refuse cookies, please note that some parts of this site may become inaccessible or not function properly. For more information about the cookies we use and the reasons why we use them, please see our <a href="https://www.buddingsuperstars.com"><u>Cookies Policy</u></a>.
      </p>

      <p style={styles.policyHeader}>
        Disclosure
      </p>
      <p style={styles.policyParagraph}>
        We may have to share your personal data with third parties, including third party service providers and other group companies. 
        We require third parties to respect the security of your data, keep it confidential, and to treat it in accordance with the law. 
      </p>
      <p style={styles.policyParagraph}>
        We will share your personal information with third parties where required by law, where it is necessary to perform a contract with you (if you are a student or a franchisee) or where we have another legitimate interest in doing so.       
      </p>
      <p style={styles.policyParagraph}>
        We may share your personal data with the third parties set out below:        
        <p className='ml-10'>
          <li>Franchisees who operate the Budding Superstars Education Practice, hire personnel and  provide tuition services to students subject to a written franchise agreement. We will share this data in order to perform a contract with you, to comply with a legal obligation and/or in our legitimate interests of maintaining a viable franchise network. </li>
          <li>Third party service providers, including hosting providers, IT support providers, analytics and search engine providers, social media platform providers. These third party service providers are only permitted to process personal data for specified purposes and, where they are processing data on our behalf, in accordance with our instructions. </li>
          <li>Other group companies as part of our regular reporting activities on company performance, in the context of a business reorganisation or group restructuring exercise, for system maintenance support and hosting of data.  </li>
          <li>Third parties to whom we may choose to sell, transfer or merge parts of our business or assets. Alternatively, we may seek to acquire other businesses or merge with them. </li>
          <li>If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce any agreements, or to protect the rights, property or safety of the KMEC group, our customers, or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction, with HM Revenue & Customs, regulators and other authorities where we are required to do so by law.</li>
          <li>Professional advisers, including lawyers, bankers, auditors and insurers who provide consultancy, banking, legal, insurance and accounting services.</li>
        </p>
      </p>
      <p style={styles.policyParagraph}>
        We require all our data processors to respect the security of your personal data and to treat it in accordance with the law. We do not allow our data processors to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions as set out in our data sharing agreements.        
      </p>
      <p style={styles.policyParagraph}>
        In some instances where we share data with third parties, such as the HMRC, those third parties will also be controllers of your data. We shall not be responsible or liable for the way in which other data controllers hold or process your personal data. Please contact those third parties for further information regarding how they will use your data. We shall only share your personal data with third parties in accordance with this privacy policy.       
      </p>
      <p style={styles.policyParagraph}>
        This site may contain links to the websites of our partner networks, advertisers and affiliates, which are outside of our control and are not covered by this Privacy Policy.  If you access other sites using the links provided, the operators of these sites may collect information from you which will be used by them in accordance with their own privacy policies.  We would encourage you to read the privacy policies on the other websites you visit.
      </p>

      <p style={styles.policyHeader}>
        International transfers
      </p>
      <p style={styles.policyParagraph}>
        The personal data that we collect from you may be transferred to, and stored at, a destination outside the European Economic Area (“EEA”).       
      </p>
      <p style={styles.policyParagraph}>
      Whenever we transfer your personal data out of the EEA, we shall ensure a similar degree of protection is afforded to your personal data by ensuring at least one of the following safeguards is implemented:        
        <p className='ml-10'>
          <li>Where we need to transfer your personal data intra-group transfers, we may put in place binding corporate rules which shall ensure that your personal data is afforded the same level of protection as it has within the EEA. For further details, see <a href="https://commission.europa.eu/law/law-topic/data-protection/data-transfers-outside-eu/binding-corporate-rules_en"><u>European Commission: Binding Corporate Rules</u></a>.</li>
          <li>We will transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission. For further details, see  <a href="https://commission.europa.eu/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en"><u>European Commission: Adequacy of the protection of personal data in non-EU countries</u></a>.</li>
          <li>In some instances, we may use specific contracts approved by the European Commission which give personal data the same protection it has in the EEA. For further details, see <a href="https://commission.europa.eu/law/law-topic/data-protection/data-transfers-outside-eu/model-contracts-transfer-personal-data-third-countries_en"><u>European Commission: Model contracts for the transfer of personal data to third countries</u></a>.</li>
          <li>Where we use providers based in the US, we may transfer data to them if they are part of the Privacy Shield which requires them to provide similar protection to personal data shared between the EEA and the US. For further details, see <a href="https://commission.europa.eu/law/law-topic/data-protection/data-transfers-outside-eu/eu-us-privacy-shield_en"><u>European Commission: EU-US Privacy Shield</u></a>.</li>
        </p>
      </p>
      <p style={styles.policyParagraph}>
        Please contact us if you want further information on the specific mechanism used by us when transferring your personal data outside of the EEA. 
      </p>

      <p style={styles.policyHeader}>
        Data security
      </p>
      <p style={styles.policyParagraph}>
        We have put in place appropriate security measures to prevent your personal information from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed (including appropriate firewalls, encryption technology such as HTTPS, and passwords). Unfortunately, however, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted to this site; any transmission is at your own risk. Once we have received your information, we will use strict procedures and security features to try to prevent unauthorised access.       
      </p>
      <p style={styles.policyParagraph}>
        In addition, the personal information you provide to us is only available to authorised personnel of the KMEC group who need access to the information in order to fulfil their duties. They will only process your personal information on our instructions and they shall be subject to a duty of confidentiality.
      </p>
      <p style={styles.policyParagraph}>
        We have put in place procedures to deal with any suspected data security breach and will notify you and any applicable regulator of a suspected breach where we are legally required to do so. 
      </p>
      <p style={styles.policyParagraph}>
        Once we no longer require your personal information, we will take reasonable steps to destroy it in a secure manner. 
      </p>

      <p style={styles.policyHeader}>
        Data retention
      </p>
      <p style={styles.policyParagraph}>
        We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for. 
      </p>
      <p style={styles.policyParagraph}>
        To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.
      </p>
      <p style={styles.policyParagraph}>
        In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) in order to develop our business methods and strategy or for research or statistical purposes, in which case we may use this information indefinitely without further notice to you.
      </p>
      <p style={styles.policyParagraph}>
        If you have registered to use the Student Portal, we will typically retain your personal data for the duration of your academic cycle (i.e. for so long as you remain in education). We will keep this under review and may periodically delete some of your personal data which we no longer require for the purposes set out in the table above. 
      </p>
      <p style={styles.policyParagraph}>
        If we have a contractual relationship with you, we will retain your personal data for the duration of that relationship and a period afterwards (such period shall be determined by our Student Privacy Policy or Centre Personnel Privacy Policy, as applicable).      
      </p>
      <p style={styles.policyParagraph}>
        In some circumstances we may be entitled to retain your data for a longer period in order to respond to you questions or complaints, to demonstrate we treated you fairly, to maintain records according to rules which apply to us and/or where we are under a legal obligation to do so, for example in the event of a legal dispute. We will also need to keep a record of your contact details if you have opted-out of receiving marketing communications from us to ensure we do not send these to you in future. 
      </p>
      <p style={styles.policyParagraph}>
        We shall have no liability to you for any deletion of your personal data in accordance with our retention policy.
      </p>

      <p style={styles.policyHeader}>
        Your Rights
      </p>
      <p style={styles.policyParagraph}>
      You have the following rights in respect of the personal data that we process about you (where we determine the purpose and means for which that personal data shall be processed):
        <p className='ml-10'>
          <li>the right to request <b>access</b> to your personal data that we hold and to receive certain information relating to that data; </li>
          <li>the right to ask us to <b>rectify</b> inaccurate data or to complete incomplete data;</li>
          <li>a right to receive or ask for your personal data to be <b>transferred</b> to a third party(note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you);</li>
          <li>the right to request the <b>erasure</b> of personal data where there is no good reason for us continuing to process it (note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request); </li>
          <li>the right to <b>object</b> to how we process your personal data where we believe we have a legitimate interest in processing it (as explained above) (note that in some cases we may demonstrate that we have compelling legitimate grounds to process your data which override your rights and freedoms);</li>
          <li>the right to <b>restrict</b> processing of your personal data in certain scenarios, for example if you want us to establish the accuracy of the data or you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it (note that when processing is restricted, we are allowed to retain sufficient information about you to ensure that the restriction is respected in future; and</li>
          <li>where you have provided your consent to the collection, processing and transfer of your personal information for a specific purpose, you have the right to <b>withdraw your consent</b> for that specific processing at any time. Once we have received notification that you have withdrawn your consent, we will no longer process your information for the purpose or purposes you originally agreed to, unless we have another legitimate basis for doing so in law. If you withdraw your consent, we may not be able to provide certain services to you.  </li>
        </p>
      </p>
      <p style={styles.policyParagraph}>
        If you wish to make a subject access request please email us at <a href="mailto:info@buddingsuperstars.com">info@buddingsuperstars.com</a> or write to us at the following address:      
      </p>
      <p style={styles.policyParagraph}>
        Budding Superstars <br/>
        One Tech Hub<br/>
        Schoolhill<br/>
        AB10 1JQ
      </p>
      <p style={styles.policyParagraph}>
        We may ask you to verify your identity if you make a request to us to exercise any of the rights set out above. We may also contact you to ask you for further information in relation to your request to speed up our response. You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.      
      </p>

      <p style={styles.policyHeader}>
        How to complain
      </p>
      <p style={styles.policyParagraph}>
        We try to meet the highest standards when collecting and using personal information. For this reason, we take any complaints we receive about this very seriously. We encourage people to bring it to our attention if they think that our collection or use of information is unfair, misleading or inappropriate. We would also welcome any suggestions for improving our procedures.
      </p>
      <p style={styles.policyParagraph}>
        Please let us know if you are unhappy with how we have used your personal information. You may contact us using our secure online contact form.
      </p>
      <p style={styles.policyParagraph}>
        You have the right to make a complaint at any time to the Information Commissioner’s Office (ICO), the UK supervisory authority for data protection issues (<a href="https://www.ico.org.uk"><u>www.ico.org.uk</u></a>). We would, however, appreciate the chance to deal with your concerns before you approach the ICO, so please do contact us in the first instance and we shall endeavour to resolve your complaint. 
      </p>

      <p style={styles.policyHeader}>
        Changes to your data
      </p>
      <p style={styles.policyParagraph}>
        The KMEC group makes every effort to ensure information collected is accurate and up-to-date. We encourage you to help us by telling us promptly if you change your contact details. You have the right to question any information we hold about you that you think is wrong or incomplete. Please contact us if you want to do this. If you do, we will take reasonable steps to check its accuracy and correct it. 
      </p>
      <p style={styles.policyParagraph}>
        We will also send reminders to you on a termly basis (if you are a student) to check the information we hold about you and to remind you to update us of any changes to your personal data that you have provided to us.
      </p>
      <p style={styles.policyParagraph}>
        This Privacy Policy was last updated in February 2023. 
      </p>

      <Divider space='4' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpagePrivacyPolicyBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/privacy-policy", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default PrivacyPolicy

